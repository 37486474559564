@import "styles/core.scss";
.container {
  width: 100%;
}

.title {
  @include text-300-bold;
  margin-bottom: var(--space-100);
  white-space: nowrap;
  color: var(--color-text-primary-inverse);
}

.membersIconsContainer {
  @include flex-row;
  white-space: wrap;
  flex-wrap: wrap;
}

.logo {
  margin-right: var(--space-300);
  margin-bottom: var(--space-150);
}

@include minWidth(480px) {
  .logo {
    margin-right: 32px;
    margin-bottom: var(--space-100);
  }
}

@include medium-up {
  .title {
    margin-bottom: var(--space-300);
  }
}

@include minWidth(1024px) {
  .membersIconsContainer {
    white-space: nowrap;
  }
}
