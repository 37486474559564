@import "styles/core.scss";
// Unset buttons styles so that it looks like a footer link
.manageCookiesBtn {
  @include removeDefaultButtonStyles;
  color: var(--color-text-primary-inverse);
  &:hover {
    color: var(--color-text-primary-inverse);
    text-decoration: underline;
  }
}
